// useSession.js
import { useSnapshot } from "valtio";
import { sessionState } from "../auth";

export const useAuthSession = () => {
    // console.log("Session state;", sessionState)
    const snapshot = useSnapshot(sessionState);
    return snapshot.session;
};

export default useAuthSession;

